body {
  margin: 0;
  nav {
    background-color: rgb(49, 49, 49);
    padding: 10px;
    font-family: arial;
    ul {
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      a {
        color: white;
      }
    }
  }
  .pokemon-list {
    display: grid;
    max-width: 1000px;
    margin: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding: 10px;
    & > div {
      border: 1px solid grey;
      padding: 20px;
      a {
        display: inline;
      }
    }
  }
}
